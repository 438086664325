import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Tooltip from 'rc-tooltip';
import { format, addMonths, subMonths, addDays, subDays, isBefore, isAfter, isSameDay, getDay, endOfMonth, differenceInDays, max, startOfMonth } from 'date-fns';
import Input from '@components/Forms/Input';
import { ArrowBackIcon, ArrowForwardIcon } from '@components/Icons';
import Loader from '@components/Core/Loader';
import DateUtility from '@utilities/DateUtility';
import GeneralStatic from '@utilities/static/GeneralStatic';

const DAYS_OF_WEEK_LABELS = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];

class DateRangePicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openMoveIn: false,
      openMoveOut: false,
      currentMonth: startOfMonth(new Date()),
      currentMonthWeeks: [],
      hoverDay: null,
      moveIn: props.moveIn ? DateUtility.parseISODate(props.moveIn) : null,
      moveOut: props.moveOut ? DateUtility.parseISODate(props.moveOut) : null,
      shouldTriggerCallbackWhenClosed: false,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.moveIn !== prevState.moveIn || nextProps.moveOut !== prevState.moveOut) {
      return {
        moveIn: DateUtility.parseISODate(nextProps.moveIn),
        moveOut: DateUtility.parseISODate(nextProps.moveOut),
      };
    }

    return null;
  }

  async componentDidMount() {
    await this.checkPickerDates();
    await this.setupPicker();

    // Notify the parent that the date picker is setup
    const { datePickerInitialized } = this.props;
    if (datePickerInitialized) {
      datePickerInitialized();
    }

    document.addEventListener('click', this.bodyClickHandler);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.bodyClickHandler);
  }

  /**
   * Check if current set dates are valid
   */
  async checkPickerDates() {
    const { moveIn, moveOut } = this.state;
    const { dateSelected } = this.props;

    if (moveIn && moveOut) {
      let moveInDisabled = this.checkIfDisabled(moveIn, true);
      let moveOutDisabled = this.checkIfDisabled(moveOut, true);
      if (moveInDisabled || moveOutDisabled) {
        await this.setState({
          moveIn: null,
          moveOut: null,
        });
        dateSelected('moveIn', null);
        dateSelected('moveOut', null);
      }
    }
  }

  /**
   * Set pick for currently set month
   */
  async setupPicker(date) {
    const newCurrentMonth = startOfMonth(date || new Date());
    const newCurrentWeeks = this.generateCurrentMonth(newCurrentMonth);

    await this.setState({
      currentMonth: newCurrentMonth,
      currentMonthWeeks: newCurrentWeeks,
    });
  }

  /**
   * Build current month
   */
  generateCurrentMonth(month) {
    // set utc offset to get correct dates in future (when timezone changes)
    const firstOfMonth = startOfMonth(month);
    const lastOfMonth = endOfMonth(month);
    const firstDayOfWeek = 0;

    // calculate the exact first and last days to fill the entire matrix
    // (considering days outside month)
    const prevDays = ((getDay(firstOfMonth) + 7 - firstDayOfWeek) % 7);
    const nextDays = ((firstDayOfWeek + 6 - getDay(lastOfMonth)) % 7);
    const firstDay = subDays(firstOfMonth, prevDays);
    const lastDay = addDays(lastOfMonth, nextDays);

    const totalDays = differenceInDays(lastDay, firstDay) + 1;

    let currentDay = firstDay;
    const weeksInMonth = [];

    for (let i = 0; i < totalDays; i += 1) {
      if (i % 7 === 0) {
        weeksInMonth.push([]);
      }

      let day = null;
      if ((i >= prevDays && i < (totalDays - nextDays))) {
        day = currentDay;
      }

      weeksInMonth[weeksInMonth.length - 1].push(this.buildDay(day));
      currentDay = addDays(currentDay, 1);
    }

    return weeksInMonth;
  }

  nextMonth = async () => {
    let { currentMonth } = this.state;
    currentMonth = addMonths(currentMonth, 1);
    await this.setState({
      currentMonth,
      currentMonthWeeks: this.generateCurrentMonth(currentMonth),
    });
  }

  prevMonth = async () => {
    let { currentMonth } = this.state;
    currentMonth = subMonths(currentMonth, 1);
    await this.setState({
      currentMonth,
      currentMonthWeeks: this.generateCurrentMonth(currentMonth),
    });
  }

  /**
   * Determine if the day should be disabled based on 5 main criteria:
   * 1. If date is in past
   * 2. If date is before the selected start date
   * 3. If date is booked
   * 4. If date is less than min stay (default 1 month) [spanning min is not pro-rated]
   * 5. If date is more than max stay (default 18 months)
   */
  checkIfDisabled(day, preview = false) {
    let minStay = this.minStay();
    let maxStay = this.maxStay();

    const { listing } = this.props;
    const { moveIn, openMoveOut } = this.state;

    if (DateUtility.sameOrBefore('day', day, new Date())) {
      return true; // dont show tooltip

    } else if (
      (openMoveOut || preview) && // dont disable if move in is open
      moveIn &&
      isBefore(day, moveIn)
    ) {
      return true; // dont show tooltip and dont disable is move in is open

    } else if (this.inAvailabilityEventRange(day)) {
      return 'This date is not available.';
    } else if (this.notAvailableForMoveInDueToMinStay(day)) {
      return `This move-in date is unavailable because it would prevent you from booking the ${this.getMinimumStayString()} minimum stay.`;
    } else if (
      (openMoveOut || preview) && // dont disable if move in is open
      moveIn &&
      !isSameDay(day, moveIn) && // dont disable current day
      this.violatesMinStay(day, minStay) // only allow the max of minStay
    ) {
      // disabled is within min stay of move in and not same day as move in and if move out is not selected
      return listing ? `This listing has a ${this.getMinimumStayString()} minimum stay.` : 'This date is not available.';

    } else if (
      (openMoveOut || preview) && // dont disable is move in is open
      moveIn &&
      this.violatesMaxStay(day, maxStay) // look max stay ahead of moveIn
    ) {
      // disabled is within max stay of move in
      return listing ? `This listing has a ${maxStay}-month maximum stay.` : 'All Kopa listings have a 18-month maximum stay.';

    } else if (moveIn && (openMoveOut || preview) && this.inUnavailableRange(day)) {
      // disabled if movein selected and unavailable dates between day and start
      return 'This move-out date is unavailable because not all dates are available after your selected move-in date.';

    } else {
      return false;
    }
  }

  getMinimumStayString() {
    const minStay = this.minStay();
    return minStay === 1 ? `${GeneralStatic.minimumDaysForNonShortTermRental} day` : minStay + '-month';
  }

  /**
   * Returns the min amount of months allowed (defaults to 0 months)
   */
  minStay() {
    const { listing } = this.props;
    return listing && listing.get('min_stay') ? Math.max(listing.get('min_stay'), 0) : 0;
  }

  /**
   * Returns the max amount of months allowed (defaults to 18 months)
   */
  maxStay() {
    const { listing } = this.props;
    return listing && listing.get('max_stay') ? listing.get('max_stay') : 18;
  }

  /**
   * Checks if the day is an a range of already blocked/booked dates + the minimum stay requirement
   */
  notAvailableForMoveInDueToMinStay(day) {
    const { availabilityEvents } = this.props;
    const { openMoveIn } = this.state;

    // Don't allow the user to select move in dates that are within minStay(months) + 1 day.
    // This only adds confusion when you are able to select one of those dates but then never a move out date.
    // Note that 1 day is added to the start of the range because of the following example:
    // Availability event from August 1 - October 1. If the min stay is 2 months, the user should be able to
    // select June 1 as the move in date. If we don't add 1 day to the calculation, it would see June 1 as unavailable as well.
    if (openMoveIn) {
      const minStay = this.minStay();
      return availabilityEvents &&
        availabilityEvents.find(ae => (
          DateUtility.isBetween(
            day,
            addDays(minStay === 1 ? subDays(DateUtility.parseISODate(ae.get('start')), 28) : subMonths(DateUtility.parseISODate(ae.get('start')), this.minStay()), 1),
            DateUtility.parseISODate(ae.get('end')),
            'day'
          )
        ));
    }

    return false;
  }

  /**
   * Checks if the day is within an unavailablility block
   */
  inAvailabilityEventRange(day) {
    const { availabilityEvents } = this.props;

    return availabilityEvents &&
      availabilityEvents.find(ae => (
        DateUtility.isBetween(
          day,
          DateUtility.parseISODate(ae.get('start')),
          DateUtility.parseISODate(ae.get('end')),
          'day'
        )
      ));
  }

  violatesAvailability(newMoveIn) {
    const { availabilityEvents } = this.props;
    const { moveOut } = this.state;

    return availabilityEvents &&
      availabilityEvents.find(ae => (
        DateUtility.isBetween(
          DateUtility.parseISODate(ae.get('start')),
          newMoveIn,
          moveOut,
          'day'
        ) ||
        DateUtility.isBetween(
          DateUtility.parseISODate(ae.get('end')),
          newMoveIn,
          moveOut,
          'day'
        )
      ));
  }

  /**
   * Checks if 'moveOut' is in a valid range for the listing and site standards
   */
  violatesMinStay(moveOut, minStay, selectedMoveIn = undefined) {
    const moveIn = selectedMoveIn || this.state.moveIn || new Date();

    let maxDate = max([
      minStay === 1 ? addDays(moveIn, 28) : addMonths(moveIn, minStay),
      addDays(moveIn, 1),
    ]);

    return isBefore(moveOut, maxDate);
  }

  /**
   * Checks if 'day' is less than the max date for the listing and site standards
   */
  violatesMaxStay(day, maxStay) {
    const { moveIn } = this.state;

    let maxDate = addMonths(moveIn || new Date(), maxStay);

    return isAfter(day, maxDate);
  }

  /**
   * Determines the minimum end date the user can choose based on the start date and the
   * minimum stay requirements of the listing and site standards
   */
  determineMinimumEndDate(moveIn) {
    const minStay = this.minStay();

    // Find the max of listing min and site min
    let minDate = max([
      addMonths(moveIn || new Date(), minStay),
      addDays(moveIn || new Date(), 1),
    ]);

    return minDate;
  }

  /**
   * Checks if the day is between start and an avilable date
   */
  inUnavailableRange(day) {
    const { moveIn } = this.state;
    const { availabilityEvents } = this.props;

    return availabilityEvents &&
      isAfter(day, moveIn || new Date()) &&
      availabilityEvents.find(ae => (
        isAfter(day, DateUtility.parseISODate(ae.get('start'))) && isAfter(DateUtility.parseISODate(ae.get('start')), moveIn || new Date())
      ));
  }

  /**
   * Open the visibility of the date picker
   */
  async openPicker(action) {
    const { moveIn, moveOut } = this.state;

    if (action === 'out' && !moveIn) {
      action = 'in';
    }
    await this.setState({
      shouldTriggerCallbackWhenClosed: false,
      openMoveIn: action === 'in',
      openMoveOut: action === 'out',
    });

    if (action === 'out') {
      this.setupPicker(moveOut || this.determineMinimumEndDate(moveIn)); // show min move out date if one hasn't already been selected
    } else {
      this.setupPicker(moveIn);
    }
  }

  /**
  * Close the date picker on a body click
  */
  bodyClickHandler = async (ev) => {
    const { dateSelected, identifier } = this.props;
    const { moveOut, openMoveIn, openMoveOut, shouldTriggerCallbackWhenClosed } = this.state;

    let wrapperId = `date-range-picker-wrapper-${identifier}`;
    if (document.getElementById(wrapperId) && !document.getElementById(wrapperId).contains(ev.target)) {
      await this.setState({
        shouldTriggerCallbackWhenClosed: false,
        openMoveIn: false,
        openMoveOut: false,
      });

      // The parent components/pages trigger their actions based on the moveOut date callback.
      // Instead of going to all of those places and updating them, we'll trigger this callback
      // upon closing so they know to update their state.
      if ((openMoveIn || openMoveOut) && shouldTriggerCallbackWhenClosed && moveOut) {
        dateSelected('moveOut', format(moveOut, 'yyyy-MM-dd'));
      }
    }
  }

  /**
   * Select a month
   */
  async selectDay(day, weekIndex, dayIndex) {
    const { dateSelected, listing } = this.props;
    let { moveIn, moveOut, openMoveIn, openMoveOut } = this.state;

    if (day.disabled || !day.date) return;

    if (openMoveOut && (isSameDay(moveIn || new Date(), day.date) || this.violatesMinStay(day.date, (listing && listing.get('min_stay')) ? listing.get('min_stay') : 0, moveIn))) return;

    // Update move in/out in local scope
    if (openMoveIn) {
      if (this.violatesMinStay(moveOut, this.minStay(), day.date) || this.violatesAvailability(day.date)) {
        moveOut = null;
      }

      await this.setState({
        shouldTriggerCallbackWhenClosed: true,
        moveIn: day.date,
        moveOut: moveOut,
      });
    } else {
      await this.setState({
        shouldTriggerCallbackWhenClosed: false,
        moveOut: day.date,
      });
    }

    // Close and open move in/out popover, and let parent know date was selected
    if (openMoveIn) {

      // Add timeout to allow body click handler to complete
      setTimeout(async () => {
        await this.setState({
          openMoveIn: false,
          openMoveOut: true,
        });

        dateSelected('moveIn', format(day.date, 'yyyy-MM-dd'));

        if (!moveOut) {
          dateSelected('moveOut', null);
        }

        this.setupPicker(moveOut || addMonths(day.date, 1));

        // Set hover day on the day of the month that aligns with the day just selected.
        // The onMouseEnter event doesn't trigger when the mouse is already located inside of a
        // day that renders where the mouse is so this manually sets that day to be the currently
        // hovered day, which applies the proper highlighting of days in between the move in and move out
        // dates
        let { currentMonthWeeks: updatedCurrentMonthWeeks } = this.state;
        const weekOfNewMonth = updatedCurrentMonthWeeks[weekIndex];
        const dayOfNewMonth = weekOfNewMonth ? weekOfNewMonth[dayIndex] : undefined;

        if (dayOfNewMonth && dayOfNewMonth.date) {
          this.setHoverDay(dayOfNewMonth.date);
        }
      });

    } else {
      await this.setState({
        openMoveIn: false,
        openMoveOut: false,
      });
      dateSelected('moveOut', format(day.date, 'yyyy-MM-dd'));
    }
  }

  /**
   * Description of day attributes
   * -> date - the date string
   * -> selected - if the day is the selected moveIn or moveOut
   * -> hovered - if the day is actively hovered between the selected moveIn and the unselected moveOut
   * -> isBetween - if the moveIn and moveOut are selected then show hover style between dates and overrided disabled
   * -> disabled - if the dates arent available to choose
   */
  buildDay(day) {
    const  { moveIn, moveOut } = this.state;

    const isDisabled = day && this.checkIfDisabled(day);
    const isSelected = day &&
      ((moveIn && isSameDay(moveIn || new Date(), day)) ||
      (moveOut && isSameDay(moveOut || new Date(), day)));

    const isBetween = day &&
      moveIn &&
      moveOut &&
      DateUtility.isBetween(
        day,
        moveIn || new Date(),
        moveOut || new Date()
      );

    return {
      date: day,
      hovered: false,
      disabled: isDisabled,
      selected: isSelected,
      isBetween,
    };
  }

  setHoverDay(hoverDay) {
    this.setState({
      hoverDay,
    });
  }

  /**
  * Is the day in the range of selected/hovered days
  */
  isDayInRange(day) {
    const { hoverDay, moveIn, moveOut, openMoveOut } = this.state;

    return openMoveOut &&
      !moveOut &&
      day.date &&
      hoverDay &&
      isAfter(day.date, moveIn || new Date()) &&
      DateUtility.isBetween(
        day.date,
        moveIn || new Date(),
        hoverDay
      );
  }

  /**
  * Clear the selected move in and move out dates
  */
  clearDates = async () => {
    const { datesCleared, dateSelected } = this.props;
    const { currentMonth } = this.state;

    await this.setState({
      moveIn: null,
      moveOut: null,
    });

    if (currentMonth) {
      await this.setState({
        currentMonthWeeks: this.generateCurrentMonth(currentMonth),
      });
    }

    await this.setState({
      openMoveIn: true,
      openMoveOut: false,
    });

    dateSelected('moveIn', null);
    dateSelected('moveOut', null);

    if (datesCleared) {
      datesCleared();
    }
  }

  render() {
    let {
      addDashBetweenDates, dateFormat, hideLabel, identifier, inputClassName, isLoadingAvailability, labelClassName,
      moveInPlaceholder, moveOutPlaceholder, screenSizes, showClearDates, wrapperClassName,
    } = this.props;
    const { currentMonth, currentMonthWeeks, moveIn, moveOut, openMoveIn, openMoveOut } = this.state;
    const rightAlignMoveOutCalendar = openMoveOut && (!hideLabel || screenSizes.get('xs'));

    if (screenSizes.get('xs')) {
      dateFormat = 'MMM d';
    }

    moveInPlaceholder = moveInPlaceholder || (screenSizes.get('sm') ? 'Select' : 'Select date');
    moveOutPlaceholder = moveOutPlaceholder || (screenSizes.get('sm') ? 'Select' : 'Select date');

    return (
      <div className={` ${wrapperClassName} no-select relative m-horizontal--auto layout-row layout-align-space-between-center`} id={`date-range-picker-wrapper-${identifier}`}>
        { !hideLabel && (
          <div className={`date-picker-wrapper m-bottom--none flex-50 ${openMoveIn ? 'date-picker-open' : ''}`}>
            <Input
              ariaLabel="Move-in date"
              handleFocus={this.openPicker.bind(this, 'in')}
              id={`move-in-input-${identifier}`}
              inputClassName={`cursor-pointer date-picker ${inputClassName}`}
              label="Move-In"
              labelClassName={labelClassName}
              name="moveInDate"
              placeholder={moveInPlaceholder}
              readonly
              value={moveIn ? format(moveIn, dateFormat) : null}
            />
          </div>
        )}
        { !hideLabel && (
          <div className={`date-picker-wrapper m-bottom--none p-left--small flex-50 ${openMoveOut ? 'date-picker-open' : ''}`}>
            <Input
              ariaLabel="Move-out date"
              handleFocus={this.openPicker.bind(this, 'out')}
              id={`move-out-input-${identifier}`}
              inputClassName={`cursor-pointer date-picker ${inputClassName}`}
              label="Move-Out"
              labelClassName={labelClassName}
              name="moveOutDate"
              placeholder={moveOutPlaceholder}
              readonly
              value={moveOut ? format(moveOut, dateFormat) : null}
            />
          </div>
        )}
        { hideLabel && (
          <div className={`date-picker-wrapper m-vertical--none ${openMoveIn ? 'date-picker-open' : ''}`}>
            <Input
              ariaLabel="Move-in date"
              handleFocus={this.openPicker.bind(this, 'in')}
              id="move-in-input"
              inputClassName={`cursor-pointer date-picker ${inputClassName}`}
              name="moveInDate"
              placeholder={moveInPlaceholder}
              readonly
              value={moveIn ? format(moveIn, dateFormat) : null}
            />
          </div>
        )}
        { addDashBetweenDates && <span className="m-left--small">–</span> }
        { hideLabel && (
          <div className={`date-picker-wrapper m-vertical--none p-left--small ${openMoveOut ? 'date-picker-open' : ''}`}>
            <Input
              ariaLabel="Move-out date"
              handleFocus={this.openPicker.bind(this, 'out')}
              id="move-out-input"
              inputClassName={`cursor-pointer date-picker ${inputClassName}`}
              name="moveOutDate"
              placeholder={moveOutPlaceholder}
              readonly
              value={moveOut ? format(moveOut, dateFormat) : null}
            />
          </div>
        )}
        <div className={`date-picker-popup black absolute a--top z--10 bg--white box-shadow b--gray-2 b-around--xs p-around--medium ${(openMoveIn || openMoveOut) ? 'date-picker-open' : ''} ${rightAlignMoveOutCalendar ? 'a--right': 'a--left' } ${hideLabel ? 'hide-date-picker-label' : ''}`} id={`date-picker-popup-${identifier}`}>
          <div className="layout-row layout-align-space-between-center">
            <a className={`hover-none m-right--x-small ${isBefore(currentMonth, subMonths(new Date(), 2)) ? 'disabled' :''}`} onClick={this.prevMonth}>
              <ArrowBackIcon className="icon--black inline-block icon--16" />
            </a>
            <h5>{ format(currentMonth, 'MMMM yyyy') }</h5>
            <a className={`hover-none m-left--x-small ${isAfter(moveIn || currentMonth, addMonths(new Date(), 18)) ? 'disabled' :''}`} onClick={this.nextMonth}>
              <ArrowForwardIcon className="icon--black inline-block icon--16" />
            </a>
          </div>

          <div className="m-top--small layout-row layout-align-start-start">
            { DAYS_OF_WEEK_LABELS.map((label, i) => (
              <span className="date-picker-header meta" key={i}>{ label }</span>
            ))}
          </div>

          <div className="relative m-top--medium flex-mins">
            { currentMonthWeeks.map((week, j) => (
              <div className="date-picker-week layout-row layout-align-start-start" key={`date-picker-week-${j}`}>
                { week.map((day, i) => {
                  return [null, true, false].includes(day.disabled) ? (
                    <div
                      className={`date-picker-day meta user-select-none layout-row layout-align-center-center ${!day.date ? 'blank' : ''} ${(day.disabled && !day.isBetween && !day.selected) ? 'disabled' : ''} ${(day.date && (!day.disabled || day.isBetween || day.selected)) ? 'available' : ''} ${day.selected ? 'selected' : ''} ${day.isBetween ? 'isBetween' : ''} ${this.isDayInRange(day) ? 'hovered' : ''}`}
                      key={`date-picker-day-${j}-${i}`}
                      onClick={this.selectDay.bind(this, day, j, i)}
                      onMouseEnter={this.setHoverDay.bind(this, day.date)}
                      onMouseLeave={this.setHoverDay.bind(this, null)}
                    >
                      <span>{ day.date ? format(day.date, 'd') : '' }</span>
                    </div>
                  ) : (
                    <Tooltip
                      id={`tooltip-${j}-${i}`}
                      key={`date-picker-day-${j}-${i}`}
                      mouseLeaveDelay={0}
                      overlay={day.disabled}
                      placement="top"
                    >
                      <div
                        className={`date-picker-day meta user-select-none layout-row layout-align-center-center ${!day.date ? 'blank' : ''} ${(day.disabled && !day.isBetween && !day.selected) ? 'disabled' : ''} ${(day.date && (!day.disabled || day.isBetween || day.selected)) ? 'available' : ''} ${day.selected ? 'selected' : ''} ${day.isBetween ? 'isBetween' : ''} ${this.isDayInRange(day) ? 'hovered' : ''}`}
                        onClick={this.selectDay.bind(this, day, j, i)}
                        onMouseEnter={this.setHoverDay.bind(this, day.date)}
                        onMouseLeave={this.setHoverDay.bind(this, null)}
                      >
                        <span>{ day.date ? format(day.date, 'd') : '' }</span>
                      </div>
                    </Tooltip>
                  );
                })}
              </div>
            ))}
            { isLoadingAvailability && (
              <Loader className="loader--small transform-center-center" />
            )}
          </div>
          { showClearDates && (
            <div className="m-top--medium">
              <a className="meta" onClick={this.clearDates}>Clear dates</a>
            </div>
          )}
        </div>
      </div>
    );
  }
}

DateRangePicker.propTypes = {
  // Required
  identifier: PropTypes.string.isRequired,
  dateSelected: PropTypes.func.isRequired,
  screenSizes: PropTypes.object.isRequired,
  // Optional
  addDashBetweenDates: PropTypes.bool,
  availabilityEvents: PropTypes.object,
  dateFormat: PropTypes.string,
  datePickerInitialized: PropTypes.func,
  datesCleared: PropTypes.func,
  hideLabel: PropTypes.bool,
  inputClassName: PropTypes.string,
  isLoadingAvailability: PropTypes.bool,
  labelClassName: PropTypes.string,
  listing: PropTypes.object,
  moveIn: PropTypes.string,
  moveInPlaceholder: PropTypes.string,
  moveOut: PropTypes.string,
  moveOutPlaceholder: PropTypes.string,
  showClearDates: PropTypes.bool,
  wrapperClassName: PropTypes.string,
};

DateRangePicker.defaultProps = {
  addDashBetweenDates: false,
  availabilityEvents: null,
  dateFormat: 'MMMM d',
  datePickerInitialized: null,
  datesCleared: null,
  hideLabel: false,
  inputClassName: '',
  isLoadingAvailability: false,
  labelClassName: '',
  listing: null,
  moveIn: null,
  moveInPlaceholder: null,
  moveOut: null,
  moveOutPlaceholder: null,
  showClearDates: false,
  wrapperClassName: '',
};

const mapStateToProps = (state) => ({
  screenSizes: state.get('ScreenSizes'),
});

export default connect(mapStateToProps, {})(DateRangePicker);
